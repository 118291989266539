import { Link } from 'react-router-dom';

function App() {
  return (
    <div
      className="p-10
    flex
    flex-col
    justify-center
    items-center
    h-full
    min-h-screen
    [&>a]:p-2
    [&>a]:border
    bg-red-500
    [&>a]:text-white
    [&>a]:text-6xl
    gap-10
    "
    >
      <Link to="/host">host</Link>
      <Link to="/player">player</Link>
    </div>
  );
}

export default App;
