import React from 'react';
import { io } from 'socket.io-client';

const socket = io('http://localhost:8000');

function Player() {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(true);

  const [savedName, setSavedName] = React.useState(
    localStorage.getItem('name'),
  );

  const savePlayerName = (e) => {
    e.preventDefault();
    localStorage.setItem('name', `${firstName} ${lastName}`);
    setSavedName(`${firstName} ${lastName}`);
  };

  const deleteUserName = () => {
    localStorage.removeItem('name');
    setFirstName('');
    setLastName('');
  };

  const sendData = () => {
    const date = new Date();

    socket.emit('buzz', {
      name: savedName,
      time: date.toLocaleTimeString(),
    });
    setIsDisabled(true);
    setTimeout(() => setIsDisabled(false), 5000);
  };

  React.useEffect(() => {
    if (!savedName) return;
    setIsDisabled(false);
    const [first, last] = savedName.split(' ');
    if (!firstName || !lastName) {
      setFirstName(first);
      setLastName(last);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedName]);

  if (!localStorage.getItem('name')) {
    return (
      <div className="flex flex-col justify-center items-center h-full min-h-screen">
        <form className="flex flex-col justify-center  items-stretch gap-5">
          <label htmlFor="firstName" className="flex">
            first name
            <input
              className="border border-black-500 ml-2 px-2 py-1 rounded-md flex-1"
              type="text"
              name="firstName"
              id="firstName"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </label>
          <label htmlFor="lastName">
            last name
            <input
              className="border border-black-500 ml-2 px-2 py-1 rounded-md flex-1"
              type="text"
              name="lastName"
              id="lastName"
              onChange={(e) => setLastName(e.target.value)}
            />
          </label>
          <input
            disabled={!firstName.length || !lastName.length}
            className="w-fit m-auto mt-10 disabled:text-grey-500 "
            type="submit"
            onClick={savePlayerName}
            value="let's gooo!"
          />
        </form>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-full min-h-screen">
      <p>
        Hi,
        {firstName}
        !
      </p>
      <button
        disabled={isDisabled}
        type="button"
        onClick={sendData}
        className="w-[300px]
        h-[300px]
        bg-red-500
        no-underline
        text-white
        rounded-full
        mt-10
        bg-none
        hover:scale-110
        active:bg-red-700
        disabled:bg-grey-500
        transition
        duration-150
        ease-out
        hover:ease-in"
      >
        buzzern!
      </button>
      <button type="button" className="mt-16" onClick={deleteUserName}>
        change user
      </button>
    </div>
  );
}

export default Player;
