import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './applicaition';
import Player from './player';
import Host from './host';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: 'player',
    element: <Player />,
  },
  {
    path: 'host',
    element: <Host />,
  },
  {
    path: '*',
    element: <div>oops</div>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
