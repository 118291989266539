import React from 'react';
import { io } from 'socket.io-client';
import { v4 } from 'uuid';

const socket = io(process.env.REACT_APP_BACKEND_URL);

function Host() {
  const [playersList, setPlayersList] = React.useState<
    Array<Record<string, string>>
  >([]);

  const resetUsers = () => {
    socket.emit('reset', (result) => {
      setPlayersList(result);
    });
  };

  React.useEffect(() => {
    socket.on('connectApp', (updatedPlayers) => {
      setPlayersList(updatedPlayers);
    });
  }, []);

  React.useEffect(() => {
    socket.on('update', (updatedPlayers) => {
      setPlayersList(updatedPlayers);
    });
  }, []);

  if (!playersList || !playersList.length) {
    return (
      <div className="flex flex-col items-center justify-center py-10 h-full min-h-screen">
        <h1>no users so far...🤔</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center py-10">
      <h1 className="uppercase">players</h1>
      <ul className="border border-gray-400 min-w-[95%] md:min-w-[450px]">
        {playersList.map((player) => (
          <li
            key={v4()}
            className="grid grid-cols-2 w-full [&>span]:border [&>span]:p-2 [&>span]:border-gray-400"
          >
            <span>{player.name}</span>
            <span>{player.time}</span>
          </li>
        ))}
      </ul>
      <button type="button" className="mt-10" onClick={resetUsers}>
        reset users
      </button>
    </div>
  );
}

export default Host;
